import { io } from 'socket.io-client';
import { Device } from '../../core/device';
import { Session } from '../../core/session';
import { EventDispatcherList } from '../../events';
import { EventManager } from '../../events/eventManager';
const player_color = ["#FF2453", "#F3CA20", "#20ABF3", "#1BC65F", "#DE28DC", "#FF7D00", "#2768c9", "#76b820", "#FF707E", "#b8b620", "#086A9B", "#34623F"];
const debug = true;
const debugFn = (message, callback) => {
    return (from, data) => {
        console.log(`[${message}] from ${from} : ${JSON.stringify(data)}`);
        callback(from, data);
    };
};
/**
 * The unboared API.
 */
export class SocketIoApi {
    /**
     * Create a new webview api interface
     */
    constructor(initialScene, deviceType, server) {
        /* Informations about the session */
        this.session = new Session('');
        /* The name of the scene */
        this.scene = '';
        /* The id of the host (the device that manages game state) */
        this.hostID = '';
        /* The id of the current device */
        this.myDeviceID = '';
        /* The list of the device ids of the active player */
        this.activePlayers = [];
        /**
         * The map of system events.
         */
        this.customEventManager = new EventManager();
        /**
         * The map of system events.
         */
        this.unboaredEventManager = new EventManager(EventDispatcherList);
        /* The type of device */
        this.deviceType = 'screen';
        /* The server url */
        this.serverURL = '';
        /* If the system is muted */
        this.isMuted = false;
        this.scene = initialScene;
        this.deviceType = deviceType;
        this.serverURL = server;
        this.devices = new Map();
    }
    // ---------------------
    //  MANAGE SESSIONS
    // ---------------------
    init() {
        this.wss = io(this.serverURL);
        this.__initWSSListeners();
        this.__initDefaultBehaviours();
        return this;
    }
    createSession(password, sessionURL, player) {
        if (!this.wss) {
            throw Error('Please call the init function before creating a session');
        }
        this.wss.emit('createSession', { sessionURL: sessionURL, sessionPassword: password, player: player });
    }
    joinSession(id, password, player) {
        if (!this.wss) {
            throw Error('Please call the init function before joining a session');
        }
        this.wss.emit('joinSession', { sessionID: id, sessionPassword: password, player: player });
    }
    // -----------------------
    //  MANAGE COMMUNICATION
    // -----------------------
    /**
     * Send a message to another device in the session.
     * This can either be a screen or a gamepad.
     * @param {string} deviceID the destination id
     * @param {string} message the message
     * @param {any} data additional data
     */
    send(deviceID, message, data) {
        this.setExtern(deviceID, 'message', { message, from: this.getDeviceID(), data });
    }
    /**
     * Send a message to the screen(s).
     * @param {string} message the message
     * @param {any} data additional data
     */
    emitAction(message, data) {
        const hostID = this.getHostID();
        if (hostID !== undefined) {
            this.send(hostID, message, data);
        }
    }
    /**
     * Send a message to all the other devices in
     * the session.
     * @param {string} message the message
     * @param {any} data additional data
     */
    broadcast(message, data) {
        this.loadExtern('message', { message, from: this.getDeviceID(), data });
    }
    /**
     * Turn on a message event listener.
     * @param {string} message the message
     * @param {function} callback additional data
     * @returns the event identifier
     */
    onMessage(message, callback) {
        let call = debug ? debugFn(message, callback) : callback;
        const eventID = this.customEventManager.on(message, (data) => {
            call(data.from, data.data);
        });
        return () => { this.customEventManager.off(message, eventID); };
    }
    /**
     * Add a behavior when a message is received (whatever its name).
     * @param {function} callback the function to call when a message is received
     * @returns the event identifier
     */
    onMessageReceived(callback) {
        const eventID = this.unboaredEventManager.on('onMessageReceived', (data) => {
            callback(data.message, data.from, data.data);
        });
        return () => { this.unboaredEventManager.off("onMessageReceived", eventID); };
    }
    // ---------------------
    //  MANAGE DEVICES
    // ---------------------
    /**
     * Gets the current device ids.
     * @returns the device id
     */
    getDeviceID() {
        return this.myDeviceID;
    }
    /**
     * Gets the device ids.
     * @returns the list of all gamepad ids in the session
     */
    getGamepadIDs() {
        let ids = [];
        for (let [key, value] of this.devices.entries()) {
            if (value.isGamepad()) {
                ids.push(key);
            }
        }
        return ids;
    }
    /**
     * Gets the screen ids.
     * @returns the list of all screen ids in the session
     */
    getScreenIDs() {
        let ids = [];
        for (let [key, value] of this.devices.entries()) {
            if (value.isScreen()) {
                ids.push(key);
            }
        }
        return ids;
    }
    /**
     * Get the host device id.
     * @returns the host device id or undefined if no host exists.
     */
    getHostID() {
        return this.hostID;
    }
    /**
     * Get a device by id. If the argument 'deviceID' is not set,
     * the function returns the current player.
     * @param {string} deviceID the device ID
     * @returns the device object
     */
    getDevice(deviceID) {
        let device;
        if (deviceID === undefined) {
            device = this.devices.get(this.myDeviceID);
        }
        else {
            device = this.devices.get(deviceID);
        }
        if (!device) {
            throw Error(`No device found with id(${deviceID})`);
        }
        return device;
    }
    /**
     * Get the master device id.
     * @returns the host device id or undefined if no master device exists.
     */
    getMasterID() {
        // for now, the master device is the first connected gamepad 
        let firstGamepad = { deviceID: undefined, connectionPosition: Infinity };
        for (let [key, value] of this.devices.entries()) {
            let position = value.getConnectionPosition();
            if (value.isGamepad() && (position < firstGamepad.connectionPosition)) {
                firstGamepad.deviceID = key;
                firstGamepad.connectionPosition = position;
            }
        }
        return firstGamepad.deviceID;
    }
    /**
     * Check if the device is the master of the game.
     * @param {string} deviceID the device ID
     */
    isMaster(deviceID) {
        if (deviceID === undefined) {
            return (this.getMasterID() === this.myDeviceID);
        }
        return (this.getMasterID() === deviceID);
    }
    /**
     * Check if the device is the host of the game.
     * @param {string} deviceID the device ID
     */
    isHost(deviceID) {
        return this.getDevice(deviceID).isHost();
    }
    /**
     * Check if the device is a screen.
     * @param {string} deviceID the device ID
     */
    isScreen(deviceID) {
        return this.getDevice(deviceID).isScreen();
    }
    /**
     * Connect a new device.
     * @param {string} deviceID the device identifier
     * @param data the data (must contains at least player informations)
     */
    connect(deviceID, data) {
        if (deviceID && deviceID !== this.getDeviceID() && data) {
            let newDevice = new Device();
            // Set the new device's initial state
            newDevice.setDeviceState(data.state);
            // Set the new device's initial state
            newDevice.setCustomDeviceState(data.customState);
            // Set the new player's informations
            newDevice.setPlayer(data.player);
            // Add the new device in the list of connected devices
            this.devices.set(deviceID, newDevice);
            if (!data.reply) {
                // I inform the device about the current scene (only for the host) and the active players
                if (this.isHost()) {
                    this.setExtern(deviceID, 'setScene', { scene: this.getScene() });
                    this.setExtern(deviceID, 'setActivePlayers', { activePlayers: this.getActivePlayers() });
                }
                // I inform the device about my current device state
                this.setExtern(deviceID, 'ub::connect', {
                    deviceID: this.getDeviceID(),
                    data: {
                        reply: true,
                        state: this.getDeviceState(),
                        customState: this.getCustomDeviceState(),
                        player: this.getPlayer(),
                    }
                });
            }
        }
        else {
        }
        this.unboaredEventManager.dispatch('onConnect', { deviceID });
    }
    /**
     * Add a behavior when a new device is connected
     * @param callback the new behavior
     */
    onConnect(callback) {
        const eventID = this.unboaredEventManager.on('onConnect', (data) => {
            callback(data.deviceID);
        });
        return () => { this.unboaredEventManager.off('onConnect', eventID); };
    }
    /**
     * Disconnect a device.
     * @param {string} deviceID the device identifier
     * @param data the data
     */
    disconnect(deviceID, data) {
        if (deviceID !== this.getDeviceID()) {
            this.devices.delete(deviceID);
        }
        this.unboaredEventManager.dispatch('onDisconnect', { deviceID });
    }
    /**
     * Add a behavior when a new device is connected
     * @param callback the new behavior
     */
    onDisconnect(callback) {
        const eventID = this.unboaredEventManager.on('onDisconnect', (data) => {
            callback(data.deviceID);
        });
        return () => { this.unboaredEventManager.off('onDisconnect', eventID); };
    }
    // ---------------------------
    //  MANAGE USER INFORMATIONS
    // ---------------------------
    /**
     * Gets the avatar associated with a device. If the argument 'deviceID' is not set,
     * the function returns the current avatar.
     * @param {string} deviceID the device ID
     * @returns {string | undefined} the avatar url
     */
    getAvatar(deviceID) {
        var _a;
        return ((_a = this.getPlayer(deviceID)) === null || _a === void 0 ? void 0 : _a.avatar) || "";
    }
    /**
     * Gets the username associated with a device. If the argument 'deviceID' is not set,
     * the function returns the current username.
     * @param {string} deviceID the device ID
     * @returns {string | undefined} the username
     */
    getUsername(deviceID) {
        var _a;
        return ((_a = this.getPlayer(deviceID)) === null || _a === void 0 ? void 0 : _a.username) || "";
    }
    /**
     * Gets the color associated with a device. If the argument 'deviceID' is not set,
     * the function returns the current username.
     * @param {string} deviceID the device ID
     * @returns {string | undefined} the color
     */
    getColor(deviceID) {
        var _a;
        return ((_a = this.getPlayer(deviceID)) === null || _a === void 0 ? void 0 : _a.color) || "";
    }
    /**
     * Gets the player UID associated with a device. If the argument 'deviceID' is not set,
     * the function returns the current player's UID.
     * @param {string} deviceID the device ID
     * @returns {string | undefined} the player's UID
     */
    getUID(deviceID) {
        var _a;
        return ((_a = this.getPlayer(deviceID)) === null || _a === void 0 ? void 0 : _a.uid) || "";
    }
    /**
     * Determines if the device is associated with a premium account on the current game.
     * If the argument 'deviceID' is not set, the function returns the current device.
     * @param {string} deviceID the device ID
     * @returns the user object
     */
    isPremium(deviceID) {
        return true;
    }
    /**
     * Gets a player associated with a device. If the argument 'deviceID' is not set,
     * the function returns the current player.
     * @param {string} deviceID the device ID
     * @returns the player object
     */
    getPlayer(deviceID) {
        var _a;
        return (_a = this.getDevice(deviceID)) === null || _a === void 0 ? void 0 : _a.getPlayer();
    }
    /**
     * Update the infos of a player on every devices.
     * @param {string} deviceID the device id of the updated device
     * @param {Player} player the new player object
     */
    loadPlayer(player) {
        const deviceID = this.getDeviceID();
        if (player) {
            this.setPlayer(deviceID, player);
            this.loadExtern('setPlayer', { deviceID, player });
        }
    }
    /**
     * Update the scene locally
     * @param scene the new scene
     */
    setPlayer(deviceID, player) {
        if (deviceID && player) {
            let device = this.getDevice(deviceID);
            if (device) {
                device.setPlayer(player);
                this.unboaredEventManager.dispatch('onPlayerChange', { deviceID });
            }
        }
    }
    /**
     * Adds a behavior when a user is modified
     * @param {function} callback the function called when an event is raised
     * @returns a unique identifier for this event
     */
    onPlayerChange(callback) {
        const eventID = this.unboaredEventManager.on('onPlayerChange', (data) => {
            callback(data.deviceID);
        });
        return () => { this.unboaredEventManager.off('onPlayerChange', eventID); };
    }
    // ------------------------------------
    //  MANAGE THE LIST OF ACTIVE PLAYERS
    // ------------------------------------
    /**
     * Loads the list of active players
     * @param activePlayers the list of active players
     */
    setActivePlayers(activePlayers) {
        this.activePlayers = activePlayers;
    }
    /**
     * Loads the list of active players
     * @param activePlayers the list of active players
     */
    loadActivePlayers(activePlayers) {
        this.setActivePlayers(activePlayers);
        this.loadExtern('setActivePlayers', { activePlayers });
    }
    /**
     * Returns the list of active players.
     */
    getActivePlayers() {
        return this.activePlayers;
    }
    /**
     * Adds a behavior when the list of active players is modified
     * @param {function} callback the function called when an event is raised
     * @returns a unique identifier for this event
     */
    onActivePlayersChange(callback) {
        const eventID = this.unboaredEventManager.on('onActivePlayersChange', callback);
        return () => { this.unboaredEventManager.off('onActivePlayersChange', eventID); };
    }
    // --------------------------------------------------------------
    //  WHEN THE CONNECTION TO THE WEB SOCKET SERVER IS ESTABLISHED
    // --------------------------------------------------------------
    /**
     * Add a behavior when the game is ready
     * @param callback the new behavior
     */
    onReady(callback) {
        const eventID = this.unboaredEventManager.on('onReady', callback);
        return () => { this.unboaredEventManager.off('onReady', eventID); };
    }
    // -----------------
    //  MANAGE SCENES 
    // -----------------
    /**
     * Access to the current scene
     */
    getScene() {
        return this.scene;
    }
    /**
     * Load a new scene on every devices.
     * @param scene the new scene
     */
    loadScene(scene) {
        if (scene) {
            this.setScene(scene);
            this.loadExtern('setScene', { scene });
        }
    }
    /**
     * Update the scene locally
     * @param scene the new scene
     */
    setScene(scene) {
        if (scene) {
            this.scene = scene;
            this.unboaredEventManager.dispatch('onSceneChange', { scene: scene });
        }
    }
    /**
     * Add a behavior when the scene is changed
     * @param callback the callback function
     */
    onSceneChange(callback) {
        const eventID = this.unboaredEventManager.on('onSceneChange', (data) => {
            callback(data.scene);
        });
        return () => { this.unboaredEventManager.off('onSceneChange', eventID); };
    }
    // ---------------------
    //  MANAGE DEVICE STATE
    // ---------------------
    getDeviceState(deviceID) {
        var _a;
        return ((_a = this.getDevice(deviceID)) === null || _a === void 0 ? void 0 : _a.getDeviceState()) || {};
    }
    setDeviceState(deviceID, state) {
        let device = this.getDevice(deviceID);
        if (device) {
            device.setDeviceState(state);
            this.unboaredEventManager.dispatch('onDeviceStateChange', { deviceID });
        }
    }
    loadDeviceState(deviceID, state) {
        this.setDeviceState(deviceID, state);
        this.loadExtern('setDeviceState', { deviceID, state });
    }
    getDeviceStateProperty(deviceID, key) {
        var _a;
        return (_a = this.getDevice(deviceID)) === null || _a === void 0 ? void 0 : _a.getDeviceStateProperty(key);
    }
    setDeviceStateProperty(deviceID, key, value) {
        let device = this.getDevice(deviceID);
        if (device) {
            device.setDeviceStateProperty(key, value);
            this.unboaredEventManager.dispatch('onDeviceStatePropertyChange', { deviceID, key });
        }
    }
    loadDeviceStateProperty(deviceID, key, value) {
        this.setDeviceStateProperty(deviceID, key, value);
        this.loadExtern('setDeviceStateProperty', { deviceID, key, value });
    }
    onDeviceStateChange(callback) {
        const eventID = this.unboaredEventManager.on('onDeviceStateChange', (data) => {
            callback(data.deviceID);
        });
        return () => { this.unboaredEventManager.off('onDeviceStateChange', eventID); };
    }
    onDeviceStatePropertyChange(callback) {
        const eventID = this.unboaredEventManager.on('onDeviceStatePropertyChange', (data) => {
            callback(data.deviceID, data.key);
        });
        return () => { this.unboaredEventManager.off('onDeviceStatePropertyChange', eventID); };
    }
    // -----------------------------
    //  MANAGE CUSTOM DEVICE STATE
    // -----------------------------
    getCustomDeviceState(deviceID) {
        var _a;
        return ((_a = this.getDevice(deviceID)) === null || _a === void 0 ? void 0 : _a.getCustomDeviceState()) || {};
    }
    setCustomDeviceState(deviceID, state) {
        let device = this.getDevice(deviceID);
        if (device) {
            device.setCustomDeviceState(state);
            this.unboaredEventManager.dispatch('onCustomDeviceStateChange', { deviceID });
        }
    }
    loadCustomDeviceState(deviceID, state) {
        this.setCustomDeviceState(deviceID, state);
        this.loadExtern('setCustomDeviceState', { deviceID, state });
    }
    getCustomDeviceStateProperty(deviceID, key) {
        var _a;
        return (_a = this.getDevice(deviceID)) === null || _a === void 0 ? void 0 : _a.getCustomDeviceStateProperty(key);
    }
    setCustomDeviceStateProperty(deviceID, key, value) {
        let device = this.getDevice(deviceID);
        if (device) {
            device.setCustomDeviceStateProperty(key, value);
            this.unboaredEventManager.dispatch('onCustomDeviceStatePropertyChange', { deviceID, key });
        }
    }
    loadCustomDeviceStateProperty(deviceID, key, value) {
        this.setCustomDeviceStateProperty(deviceID, key, value);
        this.loadExtern('setCustomDeviceStateProperty', { deviceID, key, value });
    }
    onCustomDeviceStatePropertyChange(callback) {
        const eventID = this.unboaredEventManager.on('onCustomDeviceStatePropertyChange', (data) => {
            callback(data.deviceID, data.key);
        });
        return () => { this.unboaredEventManager.off('onCustomDeviceStatePropertyChange', eventID); };
    }
    /// ---------------------------------
    ///  MANAGE LOCAL DEVICE STATES 
    /// ---------------------------------
    /**
     * Returns a local device state property by its name
     * @param {string} deviceID the device ID
     * @param {string} key the name of the property
     */
    getLocalDeviceStateProperty(deviceID, key) {
        var _a;
        return (_a = this.getDevice(deviceID)) === null || _a === void 0 ? void 0 : _a.getLocalDeviceStateProperty(key);
    }
    /**
     * Store a new local device state property.
     * @param {string} deviceID the device ID
     * @param {string} key the key of the property
     * @param {any} value the value of the property
     */
    setLocalDeviceStateProperty(deviceID, key, value) {
        let device = this.getDevice(deviceID);
        if (device) {
            device.setLocalDeviceStateProperty(key, value);
        }
    }
    // -----------------------
    //  MANAGE SYSTEM ACTIONS 
    // -----------------------
    /**
     * Set mute. This function will throw the onMute event.
     * @param {boolean} value true if set to mute, else set to unmute
     * @screen
     */
    mute(value) {
        this.isMuted = value;
        this.unboaredEventManager.dispatch('onMute', { value });
    }
    /**
     * Check if the system is muted.
     * @returns true if the sustem is mute
     */
    isMute() {
        return this.isMuted;
    }
    /**
     * Add a behavior when the system is muted
     * @param {function} callback the new behavior
     */
    onMute(callback) {
        const eventID = this.unboaredEventManager.on('onMute', (data) => {
            callback(data.value);
        });
        return () => { this.unboaredEventManager.off('onMute', eventID); };
    }
    /**
     * Pause the game. This function will throw the onPause event.
     * @screen
     */
    pause() {
        this.unboaredEventManager.dispatch('onPause', {});
    }
    /**
     * Add a behavior when the system is paused
     * @param {function} callback the new behavior
     */
    onPause(callback) {
        const eventID = this.unboaredEventManager.on('onPause', callback);
        return () => { this.unboaredEventManager.off('onPause', eventID); };
    }
    /**
     * Resume the game
     * @screen
     */
    resume() {
        this.unboaredEventManager.dispatch('onResume', {});
    }
    /**
     * Add a behavior when the system is resumed
     * @param {function} callback the new behavior
     */
    onResume(callback) {
        const eventID = this.unboaredEventManager.on('onResume', callback);
        return () => { this.unboaredEventManager.off('onResume', eventID); };
    }
    /**
     * Navigates to a location.
     * @param url the location
     * @screen
    */
    navigateTo(url) {
    }
    /**
     * Navigates to the home.
     * @screen
    */
    navigateToHome() {
    }
    /**
     * Return true if we are in a top level application.
     */
    isTopLevelApp() {
        return true;
    }
    // -----------------------
    //  MANAGE SESSIONS 
    // -----------------------
    getSession() {
        return this.session;
    }
    /**
     * Gets the connection link of the game controllers.
     * @returns the connexion link
     */
    getSessionLink() {
        return this.session.getURL();
    }
    /**
     * Gets the session id.
     * @returns the session id
     */
    getSessionID() {
        return this.session.getID();
    }
    /**
     * Add a behavior when the scene is changed
     * @param callback the callback function
     */
    // onSessionChange(callback: (scene: string) => void): number {
    //     return this.unboaredEventManager.on('onSessionChange', callback);
    // }
    // offSessionChange(eventID: number): void {
    //     this.offMessage('onSessionChange', eventID)
    // }
    /**
     * Get the websocket connection.
     * @returns the instance of connection
     */
    getWSS() {
        return this.wss;
    }
    setExtern(deviceID, action, data) {
        var _a;
        this.wss.emit("send", {
            action: action,
            to: deviceID,
            sessionID: (_a = this.session) === null || _a === void 0 ? void 0 : _a.getID(),
            data: data,
        });
    }
    loadExtern(action, data) {
        var _a;
        this.wss.emit("broadcast", {
            action: action,
            sessionID: (_a = this.session) === null || _a === void 0 ? void 0 : _a.getID(),
            data: data,
        });
    }
    /**
     * Initialize listeners to the websocket server.
     */
    __initWSSListeners() {
        let that = this;
        that.wss.on('message', (data) => {
            // Listen to messages
            that.customEventManager.dispatch(data.message, data);
            that.unboaredEventManager.dispatch('onMessageReceived', data);
        });
        that.wss.on('ub::connect', (data) => {
            that.connect(data.deviceID, data.data);
        });
        that.wss.on('ub::disconnect', (data) => {
            if (data.deviceID) {
                that.disconnect(data.deviceID, {});
            }
        });
        that.wss.on('ready', (data) => {
            // Set session
            that.session = new Session(data.session.id, data.session.uuid, data.session.password, data.session.url);
            // Set the device id of the host 
            that.hostID = data.hostID;
            // Set my own device id
            that.myDeviceID = data.deviceID;
            that.devices.set(that.myDeviceID, new Device());
            // Set my device's initial state
            that.setDeviceState(that.myDeviceID, {
                connectionPosition: data.connectionPosition,
                deviceType: that.deviceType,
                host: that.hostID === that.myDeviceID,
            });
            // Set my player's informations
            that.setPlayer(that.myDeviceID, Object.assign(Object.assign({}, data.player), { color: data.player.color || player_color[Math.max(data.connectionPosition - 1, 0)] || player_color[Math.floor(Math.random() * player_color.length)] }));
            // For each devices in the session, 
            // I inform it about my personal data
            for (let devID of data.devices) {
                if (devID !== that.myDeviceID) {
                    that.setExtern(devID, 'ub::connect', {
                        deviceID: that.myDeviceID,
                        data: {
                            state: that.getDeviceState(),
                            customState: {},
                            player: that.getPlayer(),
                        }
                    });
                }
            }
            that.unboaredEventManager.dispatch('onReady', {});
        });
        that.wss.on('setScene', (data) => {
            if (data.from !== that.myDeviceID) {
                that.setScene(data.scene);
            }
        });
        that.wss.on('setPlayer', (data) => {
            if (data.from !== that.myDeviceID) {
                that.setPlayer(data.deviceID, data.player);
            }
        });
        that.wss.on('setActivePlayers', (data) => {
            if (data.from !== that.myDeviceID) {
                that.setActivePlayers(data.activePlayers);
            }
        });
        that.wss.on('setDeviceState', (data) => {
            if (data.from !== that.myDeviceID) {
                that.setDeviceState(data.deviceID, data.state);
            }
        });
        that.wss.on('setDeviceStateProperty', (data) => {
            if (data.from !== that.myDeviceID) {
                that.setDeviceStateProperty(data.deviceID, data.key, data.value);
            }
        });
        that.wss.on('setCustomDeviceState', (data) => {
            if (data.from !== that.myDeviceID) {
                that.setCustomDeviceState(data.deviceID, data.state);
            }
        });
        that.wss.on('setCustomDeviceStateProperty', (data) => {
            if (data.from !== that.myDeviceID) {
                that.setCustomDeviceStateProperty(data.deviceID, data.key, data.value);
            }
        });
    }
    /**
     * Setup the default behaviours for each events.
     */
    __initDefaultBehaviours() {
        let that = this;
        /* Reconnect if deconnection */
        that.wss.on('disconnect', (reason) => {
            console.log("[WARNING] bad internet connection or server error");
            console.log("|---", reason);
            that.disconnect(that.getDeviceID());
            if (reason === "io server disconnect") {
                console.log("|--- io server disconnect");
                console.log("|--- require manual reconnection");
                // the disconnection was initiated by the server, you need to reconnect manually
                that.wss.connect();
            }
            // else the socket will automatically try to reconnect
        });
        that.wss.on('connect', () => {
            if (that.getDeviceID()) {
                console.log("[INFO] socket.io reconnection");
                that.wss.emit('updateSID', { sessionID: that.getSessionID(), deviceID: that.getDeviceID() });
                that.connect(that.getDeviceID());
            }
            else {
                console.log("[INFO] socket.io connection");
            }
        });
    }
}

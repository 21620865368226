import { Device, Session } from "../../core";
import { EventManager } from "../../events/eventManager";
const debug = true;
const debugFn = (message, callback) => {
    return (from, data) => {
        console.log(`[${message}] from ${from} : ${JSON.stringify(data)}`);
        callback(from, data);
    };
};
/**
 * The unboared API.
 */
class WebViewApi {
    /**
     * Create a new webview api interface
     */
    constructor(initialScene) {
        /* Informations about the session */
        this.session = new Session('');
        /* The name of the scene */
        this.scene = '';
        /**
         * The game developer's custom event map.
         * The events stored in this manager will be those instanciated
         * by the developer thanks to the onMessage function (or useLisener
         * for React developers).
         */
        this.customEventManager = new EventManager();
        /**
         * The system event map. The events stored in this manager are
         * those used by the unboared system. It contains two types of events.
         * Firstly, events that are handled after an attribute changes.
         * Secondly, events that are setup for an external use of the API.
         */
        this.unboaredEventManager = new EventManager();
        /* The id of the host (the device that manages game state) */
        this.hostID = '';
        /* The id of the current device */
        this.myDeviceID = '';
        /* The list of the device ids of the active player */
        this.activePlayers = [];
        /* If the system is muted */
        this.isMuted = false;
        /* For react native apps */
        this.unboaredAPI = undefined;
        this.devices = new Map();
        this.scene = initialScene;
    }
    // ---------------------
    //  MANAGE SESSIONS
    // ---------------------
    init() {
        let that = this;
        // Setup communication with parent app
        window.addEventListener("message", function (event) {
            that.__onPostMessage(event.data);
        }, false);
        // Setup external API (i.e. functions the parent app can trigger) 
        this.__initWebViewExternalListeners();
        return this;
    }
    start() {
        // Notify the parent app that current app is ready
        this.__postMessage({
            action: 'onReady',
            data: {}
        });
    }
    // -----------------------
    //  MANAGE COMMUNICATION
    // -----------------------
    /**
     * Send a message to another device in the session.
     * This can either be a screen or a gamepad.
     * @param {string} deviceID the destination id
     * @param {string} message the message
     * @param {any} data additional data
     */
    send(deviceID, message, data) {
        this.setExtern(deviceID, 'message', { message, from: this.getDeviceID(), data });
    }
    /**
     * Send a message to the screen(s).
     * @param {string} message the message
     * @param {any} data additional data
     */
    emitAction(message, data) {
        const hostID = this.getHostID();
        if (hostID !== undefined) {
            this.send(hostID, message, data);
        }
    }
    /**
     * Send a message to all the other devices in
     * the session.
     * @param {string} message the message
     * @param {any} data additional data
     */
    broadcast(message, data) {
        this.loadExtern('message', { message, from: this.getDeviceID(), data });
    }
    /**
     * Turn on a message event listener.
     * @param {string} message the message
     * @param {function} callback additional data
     * @returns the event identifier
     */
    onMessage(message, callback) {
        let call = debug ? debugFn(message, callback) : callback;
        const eventID = this.customEventManager.on(message, (data) => {
            call(data.from, data.data);
        });
        return () => { {
            this.customEventManager.off(message, eventID);
        } };
    }
    /**
     * Add a behavior when a message is received (whatever its name).
     * @param {function} callback the function to call when a message is received
     * @returns the event identifier
     */
    onMessageReceived(callback) {
        const eventID = this.unboaredEventManager.on('onMessageReceived', (data) => {
            callback(data.message, data.from, data.data);
        });
        return () => { {
            this.unboaredEventManager.off("onMessageReceived", eventID);
        } };
    }
    // ---------------------
    //  MANAGE DEVICES
    // ---------------------
    /**
     * Gets the current device ids.
     * @returns the device id
     */
    getDeviceID() {
        return this.myDeviceID;
    }
    /**
     * Gets the device ids.
     * @returns the list of all gamepad ids in the session
     */
    getGamepadIDs() {
        let ids = [];
        for (let [key, value] of this.devices.entries()) {
            if (value.isGamepad()) {
                ids.push(key);
            }
        }
        return ids;
    }
    /**
     * Gets the screen ids.
     * @returns the list of all screen ids in the session
     */
    getScreenIDs() {
        let ids = [];
        for (let [key, value] of this.devices.entries()) {
            if (value.isScreen()) {
                ids.push(key);
            }
        }
        return ids;
    }
    /**
     * Get the host device id.
     * @returns the host device id or undefined if no host exists.
     */
    getHostID() {
        return this.hostID;
    }
    /**
     * Get a device by id. If the argument 'deviceID' is not set,
     * the function returns the current player.
     * @param {string} deviceID the device ID
     * @returns the device object
     */
    getDevice(deviceID) {
        let device;
        if (deviceID === undefined) {
            device = this.devices.get(this.myDeviceID);
        }
        else {
            device = this.devices.get(deviceID);
        }
        if (!device) {
            throw Error(`No device found with id(${deviceID})`);
        }
        return device;
    }
    /**
     * Get the master device id.
     * @returns the host device id or undefined if no master device exists.
     */
    getMasterID() {
        // for now, the master device is the first connected gamepad 
        let firstGamepad = { deviceID: undefined, connectionPosition: Infinity };
        for (let [key, value] of this.devices.entries()) {
            let position = value.getConnectionPosition();
            if (value.isGamepad() && (position < firstGamepad.connectionPosition)) {
                firstGamepad.deviceID = key;
                firstGamepad.connectionPosition = position;
            }
        }
        return firstGamepad.deviceID;
    }
    /**
     * Check if the device is the master of the game.
     * @param {string} deviceID the device ID
     */
    isMaster(deviceID) {
        if (deviceID === undefined) {
            return (this.getMasterID() === this.myDeviceID);
        }
        return (this.getMasterID() === deviceID);
    }
    /**
     * Check if the device is the host of the game.
     * @param {string} deviceID the device ID
     */
    isHost(deviceID) {
        return (deviceID) ? (deviceID === this.hostID) : this.myDeviceID === this.hostID;
    }
    /**
     * Check if the device is a screen.
     * @param {string} deviceID the device ID
     */
    isScreen(deviceID) {
        return this.getDevice(deviceID).isScreen();
    }
    /**
     * Connect a new device.
     * @param {string} deviceID the device identifier
     * @param data the data (must contains at least player informations)
     */
    connect(deviceID, data) {
        if (deviceID && deviceID !== this.getDeviceID()) {
            let newDevice = new Device();
            // Set my device's initial state
            newDevice.setDeviceState(data.state);
            // Set my player's informations
            newDevice.setPlayer(data.player);
            // Add the new device in the list of connected devices
            this.devices.set(deviceID, newDevice);
        }
        this.unboaredEventManager.dispatch('onConnect', { deviceID });
    }
    /**
     * Add a behavior when a new device is connected
     * @param callback the new behavior
     */
    onConnect(callback) {
        const eventID = this.unboaredEventManager.on('onConnect', (data) => {
            callback(data.deviceID);
        });
        return () => { this.unboaredEventManager.off('onConnect', eventID); };
    }
    /**
     * Disconnect a device.
     * @param {string} deviceID the device identifier
     * @param data the data
     */
    disconnect(deviceID, data) {
        if (deviceID !== this.getDeviceID()) {
            this.devices.delete(deviceID);
        }
        this.unboaredEventManager.dispatch('onDisconnect', { deviceID });
    }
    /**
     * Add a behavior when a new device is connected
     * @param callback the new behavior
     */
    onDisconnect(callback) {
        const eventID = this.unboaredEventManager.on('onDisconnect', (data) => {
            callback(data.deviceID);
        });
        return () => { this.unboaredEventManager.off('onDisconnect', eventID); };
    }
    // ---------------------------
    //  MANAGE USER INFORMATIONS
    // ---------------------------
    /**
     * Gets the avatar associated with a device. If the argument 'deviceID' is not set,
     * the function returns the current avatar.
     * @param {string} deviceID the device ID
     * @returns {string | undefined} the avatar url
     */
    getAvatar(deviceID) {
        var _a;
        return ((_a = this.getPlayer(deviceID)) === null || _a === void 0 ? void 0 : _a.avatar) || "";
    }
    /**
     * Gets the username associated with a device. If the argument 'deviceID' is not set,
     * the function returns the current username.
     * @param {string} deviceID the device ID
     * @returns {string | undefined} the username
     */
    getUsername(deviceID) {
        var _a;
        return ((_a = this.getPlayer(deviceID)) === null || _a === void 0 ? void 0 : _a.username) || "";
    }
    /**
     * Gets the color associated with a device. If the argument 'deviceID' is not set,
     * the function returns the current username.
     * @param {string} deviceID the device ID
     * @returns {string | undefined} the color
     */
    getColor(deviceID) {
        var _a;
        return ((_a = this.getPlayer(deviceID)) === null || _a === void 0 ? void 0 : _a.color) || "";
    }
    /**
     * Gets the player UID associated with a device. If the argument 'deviceID' is not set,
     * the function returns the current player's UID.
     * @param {string} deviceID the device ID
     * @returns {string | undefined} the player's UID
     */
    getUID(deviceID) {
        var _a;
        return ((_a = this.getPlayer(deviceID)) === null || _a === void 0 ? void 0 : _a.uid) || "";
    }
    /**
     * Gets a player associated with a device. If the argument 'deviceID' is not set,
     * the function returns the current player.
     * @param {string} deviceID the device ID
     * @returns the player object
     */
    getPlayer(deviceID) {
        var _a;
        return (_a = this.getDevice(deviceID)) === null || _a === void 0 ? void 0 : _a.getPlayer();
    }
    /**
     * Determines if the device is associated with a premium account on the current game.
     * If the argument 'deviceID' is not set, the function returns the current device.
     * @param {string} deviceID the device ID
     * @returns the user object
     */
    isPremium(deviceID) {
        return true;
    }
    /**
     * Update the infos of a player on every devices.
     * @param {string} deviceID the device id of the updated device
     * @param {Player} player the new player object
     */
    loadPlayer(player) {
        const deviceID = this.getDeviceID();
        if (player) {
            this.setPlayer(deviceID, player);
            this.loadExtern('setPlayer', { deviceID, player });
        }
    }
    /**
     * Update the player informations locally.
     * @param deviceID the device id
     * @param player the new value for the player
     */
    setPlayer(deviceID, player) {
        if (deviceID && player) {
            let device = this.getDevice(deviceID);
            if (device) {
                device.setPlayer(player);
                this.unboaredEventManager.dispatch('onPlayerChange', { deviceID });
            }
        }
    }
    /**
     * Adds a behavior when a user is modified
     * @param {function} callback the function called when an event is raised
     * @returns a unique identifier for this event
     */
    onPlayerChange(callback) {
        const eventID = this.unboaredEventManager.on('onPlayerChange', (data) => {
            callback(data.deviceID);
        });
        return () => { this.unboaredEventManager.off('onPlayerChange', eventID); };
    }
    // ------------------------------------
    //  MANAGE THE LIST OF ACTIVE PLAYERS
    // ------------------------------------
    /**
     * Loads the list of active players
     * @param activePlayers the list of active players
     */
    setActivePlayers(activePlayers) {
        this.activePlayers = activePlayers;
    }
    /**
     * Loads the list of active players
     * @param activePlayers the list of active players
     */
    loadActivePlayers(activePlayers) {
        this.setActivePlayers(activePlayers);
        this.loadExtern('setActivePlayers', { activePlayers });
    }
    /**
     * Returns the list of active players.
     */
    getActivePlayers() {
        return this.activePlayers;
    }
    /**
     * Adds a behavior when the list of active players is modified
     * @param {function} callback the function called when an event is raised
     * @returns a unique identifier for this event
     */
    onActivePlayersChange(callback) {
        const eventID = this.unboaredEventManager.on('onActivePlayersChange', callback);
        return () => { this.unboaredEventManager.off('onActivePlayersChange', eventID); };
    }
    // --------------------------------------------------------------
    //  WHEN THE CONNECTION TO THE WEB SOCKET SERVER IS ESTABLISHED
    // --------------------------------------------------------------
    /**
     * Add a behavior when the game is ready
     * @param callback the new behavior
     */
    onReady(callback) {
        const eventID = this.unboaredEventManager.on('onReady', callback);
        return () => { this.unboaredEventManager.off('onReady', eventID); };
    }
    // -----------------
    //  MANAGE SCENES 
    // -----------------
    /**
     * Access to the current scene
     */
    getScene() {
        return this.scene;
    }
    /**
     * Load a new scene on every devices.
     * @param scene the new scene
     */
    loadScene(scene) {
        if (scene) {
            this.setScene(scene);
            this.loadExtern('setScene', { scene });
        }
    }
    /**
     * Update the scene locally
     * @param scene the new scene
    */
    setScene(scene) {
        if (scene) {
            this.scene = scene;
            this.unboaredEventManager.dispatch('onSceneChange', { scene: scene });
        }
    }
    /**
     * Add a behavior when the scene is changed
     * @param callback the callback function
     */
    onSceneChange(callback) {
        const eventID = this.unboaredEventManager.on('onSceneChange', (data) => {
            callback(data.scene);
        });
        return () => { this.unboaredEventManager.off('onSceneChange', eventID); };
    }
    // ---------------------
    //  MANAGE DEVICE STATE
    // ---------------------
    getDeviceState(deviceID) {
        var _a;
        return ((_a = this.getDevice(deviceID)) === null || _a === void 0 ? void 0 : _a.getDeviceState()) || {};
    }
    setDeviceState(deviceID, state) {
        let device = this.getDevice(deviceID);
        if (device) {
            device.setDeviceState(state);
            this.unboaredEventManager.dispatch('onDeviceStateChange', { deviceID });
        }
    }
    loadDeviceState(deviceID, state) {
        this.setDeviceState(deviceID, state);
        this.loadExtern('setDeviceState', { deviceID, state });
    }
    getDeviceStateProperty(deviceID, key) {
        var _a;
        return (_a = this.getDevice(deviceID)) === null || _a === void 0 ? void 0 : _a.getDeviceStateProperty(key);
    }
    setDeviceStateProperty(deviceID, key, value) {
        let device = this.getDevice(deviceID);
        if (device) {
            device.setDeviceStateProperty(key, value);
            this.unboaredEventManager.dispatch('onDeviceStatePropertyChange', { deviceID, key });
        }
    }
    loadDeviceStateProperty(deviceID, key, value) {
        this.setDeviceStateProperty(deviceID, key, value);
        this.loadExtern('setDeviceStateProperty', { deviceID, key, value });
    }
    onDeviceStateChange(callback) {
        const eventID = this.unboaredEventManager.on('onDeviceStateChange', (data) => {
            callback(data.deviceID);
        });
        return () => { this.unboaredEventManager.off('onDeviceStateChange', eventID); };
    }
    onDeviceStatePropertyChange(callback) {
        const eventID = this.unboaredEventManager.on('onDeviceStatePropertyChange', (data) => {
            callback(data.deviceID, data.key);
        });
        return () => { this.unboaredEventManager.off('onDeviceStatePropertyChange', eventID); };
    }
    // -----------------------------
    //  MANAGE CUSTOM DEVICE STATE
    // -----------------------------
    getCustomDeviceState(deviceID) {
        var _a;
        return ((_a = this.getDevice(deviceID)) === null || _a === void 0 ? void 0 : _a.getCustomDeviceState()) || {};
    }
    setCustomDeviceState(deviceID, state) {
        let device = this.getDevice(deviceID);
        if (device) {
            device.setCustomDeviceState(state);
            this.unboaredEventManager.dispatch('onCustomDeviceStateChange', { deviceID });
        }
    }
    loadCustomDeviceState(deviceID, state) {
        this.setCustomDeviceState(deviceID, state);
        this.loadExtern('setCustomDeviceState', { deviceID, state });
    }
    getCustomDeviceStateProperty(deviceID, key) {
        var _a;
        return (_a = this.getDevice(deviceID)) === null || _a === void 0 ? void 0 : _a.getCustomDeviceStateProperty(key);
    }
    setCustomDeviceStateProperty(deviceID, key, value) {
        let device = this.getDevice(deviceID);
        if (device) {
            device.setCustomDeviceStateProperty(key, value);
            this.unboaredEventManager.dispatch('onCustomDeviceStatePropertyChange', { deviceID, key });
        }
    }
    loadCustomDeviceStateProperty(deviceID, key, value) {
        this.setCustomDeviceStateProperty(deviceID, key, value);
        this.loadExtern('setCustomDeviceStateProperty', { deviceID, key, value });
    }
    onCustomDeviceStatePropertyChange(callback) {
        const eventID = this.unboaredEventManager.on('onCustomDeviceStatePropertyChange', (data) => {
            callback(data.deviceID, data.key);
        });
        return () => { this.unboaredEventManager.off('onCustomDeviceStatePropertyChange', eventID); };
    }
    /// ---------------------------------
    ///  MANAGE LOCAL DEVICE STATES 
    /// ---------------------------------
    /**
     * Returns a local device state property by its name
     * @param {string} deviceID the device ID
     * @param {string} key the name of the property
     */
    getLocalDeviceStateProperty(deviceID, key) {
        var _a;
        return (_a = this.getDevice(deviceID)) === null || _a === void 0 ? void 0 : _a.getLocalDeviceStateProperty(key);
    }
    /**
     * Store a new local device state property.
     * @param {string} deviceID the device ID
     * @param {string} key the key of the property
     * @param {any} value the value of the property
     */
    setLocalDeviceStateProperty(deviceID, key, value) {
        let device = this.getDevice(deviceID);
        if (device) {
            device.setLocalDeviceStateProperty(key, value);
        }
    }
    // -----------------------
    //  MANAGE SYSTEM ACTIONS 
    // -----------------------
    /**
     * Set mute. This function will throw the onMute event.
     * @param {boolean} value true if set to mute, else set to unmute
     * @screen
     */
    mute(value) {
        this.unboaredEventManager.dispatch('onMute', { value: value });
    }
    /**
     * Check if the system is muted.
     * @returns true if the sustem is mute
     */
    isMute() {
        return this.isMuted;
    }
    /**
     * Add a behavior when the system is muted
     * @param {function} callback the new behavior
     */
    onMute(callback) {
        const eventID = this.unboaredEventManager.on('onMute', (data) => {
            callback(data.value);
        });
        return () => { this.unboaredEventManager.off('onMute', eventID); };
    }
    /**
     * Pause the game. This function will throw the onPause event.
     * @screen
     */
    pause() {
        this.unboaredEventManager.dispatch('onPause', {});
    }
    /**
     * Add a behavior when the system is paused
     * @param {function} callback the new behavior
     */
    onPause(callback) {
        const eventID = this.unboaredEventManager.on('onPause', callback);
        return () => { this.unboaredEventManager.off('onPause', eventID); };
    }
    /**
     * Resume the game
     * @screen
     */
    resume() {
        this.unboaredEventManager.dispatch('onResume', {});
    }
    /**
     * Add a behavior when the system is resumed
     * @param {function} callback the new behavior
     */
    onResume(callback) {
        const eventID = this.unboaredEventManager.on('onResume', callback);
        return () => { this.unboaredEventManager.off('onResume', eventID); };
    }
    /**
     * Navigates to a location.
     * @param url the location
     * @screen
    */
    navigateTo(url) {
        this.__postMessage({ action: "navigateTo", data: { url: url } });
    }
    /**
     * Navigates to the home.
     * @screen
    */
    navigateToHome() {
        this.__postMessage({ action: "navigateToHome" });
    }
    /**
     * Return true if we are in a top level application.
     */
    isTopLevelApp() {
        return false;
    }
    // -----------------------
    //  MANAGE SESSIONS 
    // -----------------------
    getSession() {
        return this.session;
    }
    /**
     * Gets the connection link of the game controllers.
     * @returns the connexion link
     */
    getSessionLink() {
        return this.session.getURL();
    }
    /**
     * Gets the session id.
     * @returns the session id
     */
    getSessionID() {
        return this.session.getID();
    }
    setExtern(deviceID, action, data) {
        this.__postMessage({
            action: "send",
            to: deviceID,
            data: {
                action: action,
                data: data,
            }
        });
    }
    loadExtern(action, data) {
        this.__postMessage({
            action: "broadcast",
            data: {
                action: action,
                data: data,
            }
        });
    }
    __postMessage(data) {
        try {
            // if (window['unboaredAPI']) {
            //   window['unboaredAPI'].postMessage(data)
            // }
            // else {
            window.parent.postMessage(data, document.referrer);
            // }
        }
        catch (e) {
            console.log("[ERROR] Posting message to parent failed: " + JSON.stringify(data));
        }
    }
    __onPostMessage(data) {
        console.log(`[WebViewChannel] Message received from parent windows ==> ${JSON.stringify(data)}`);
        if (data.action) {
            if (this.unboaredEventManager.has(data.action)) {
                this.unboaredEventManager.dispatch(data.action, data.data);
            }
        }
        else {
            console.log(`[ERROR] Message from parent does not contains require parameter 'action'`);
            return;
        }
    }
    /**
     * Initialize listeners to the websocket server.
     */
    __initWebViewExternalListeners() {
        let that = this;
        that.unboaredEventManager.on('message', (data) => {
            that.customEventManager.dispatch(data.message, data);
            that.unboaredEventManager.dispatch('onMessageReceived', data);
        });
        that.unboaredEventManager.on('connect', (data) => {
            that.connect(data.deviceID, data.data);
        });
        that.unboaredEventManager.on('disconnect', (data) => {
            that.disconnect(data.deviceID);
        });
        that.unboaredEventManager.on('ready', (data) => {
            // Set session
            that.session = new Session(data.session.id, data.session.uuid, data.session.password, data.session.url);
            // Set the device id of the host 
            that.hostID = data.hostID;
            // Set my own device id
            that.myDeviceID = data.myDeviceID;
            // Set the mute parameter
            that.isMuted = data.mute;
            // Set the connected devices informations
            for (let device of data.devices) {
                let newDevice = new Device();
                // Set my device's initial state
                newDevice.setDeviceState(device.data.state);
                // Set my player's informations
                newDevice.setPlayer(device.data.player);
                // Add the new device in the list of connected devices
                this.devices.set(device.deviceID, newDevice);
                if (device.deviceID !== that.myDeviceID) {
                    that.setExtern(device.deviceID, 'askLocalData', { from: that.myDeviceID });
                }
            }
            that.unboaredEventManager.dispatch('onReady', data);
        });
        that.unboaredEventManager.on('setScene', (data) => {
            that.setScene(data.scene);
        });
        that.unboaredEventManager.on('setPlayer', (data) => {
            that.setPlayer(data.deviceID, data.player);
        });
        that.unboaredEventManager.on('setDeviceState', (data) => {
            that.setDeviceState(data.deviceID, data.state);
        });
        that.unboaredEventManager.on('setDeviceStateProperty', (data) => {
            that.setDeviceStateProperty(data.deviceID, data.key, data.value);
        });
        that.unboaredEventManager.on('setCustomDeviceState', (data) => {
            that.setCustomDeviceState(data.deviceID, data.state);
        });
        that.unboaredEventManager.on('setCustomDeviceStateProperty', (data) => {
            that.setCustomDeviceStateProperty(data.deviceID, data.key, data.value);
        });
        that.unboaredEventManager.on('askLocalData', (data) => {
            // I inform the device about the current scene (only for the host)
            if (that.isHost()) {
                that.setExtern(data.from, 'setScene', { scene: that.getScene() });
            }
            // I inform the device about my custom state
            this.setExtern(data.from, 'setCustomDeviceState', { deviceID: that.myDeviceID, state: this.getCustomDeviceState() });
        });
        that.unboaredEventManager.on('mute', (data) => {
            that.mute(data.value);
        });
        that.unboaredEventManager.on('resume', () => {
            that.resume();
        });
        that.unboaredEventManager.on('pause', () => {
            that.pause();
        });
    }
}
export { WebViewApi };
